@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.photo-articles {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
	max-width: 790px;

	&__title {
		@extend %text-bold;
		font-size: 1.7rem;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 20px;

		@include gridle_state(md) {
			font-size: 2.1rem;
			letter-spacing: 1.35px;
		}
	}

	&__item {
		margin-bottom: 20px;

		@include gridle_state(md) {
			margin-bottom: 30px;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
