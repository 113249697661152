@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.centered-logo-block {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: auto;
	margin-left: auto;
	max-width: 790px;

	@extend %text;
	text-align: center;

	&__picto {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 5px;

		img {
			height: 30px;
			width: 30px;
		}

		@include gridle_state(md) {
			img {
				height: 45px;
				width: 45px;
			}
		}
	}

	&__title {
		@extend %font-bold;
		color: $color-primary;
		font-size: 1.6rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 15px;

		@include gridle_state(md) {
			font-size: 2.2rem;
			letter-spacing: 1.35px;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 630px;
		margin-right: auto;
		margin-left: auto;

		p,
		ul {
			margin-bottom: 10px;
		}

		em {
			font-size: 1.2rem;

			@include gridle_state(md) {
				font-size: 1.4rem;
			}
		}

		ul {
			font-size: 1.3rem;
			margin-bottom: 10px;
			color: $black;
			text-align: left;
			margin-left: 17px;

			@include gridle_state(md) {
				font-size: 1.6rem;
			}
		}

		li {
			list-style: none;
			align-items: flex-start;
			display: flex;
			margin-bottom: 5px;

			&:before {
				content: "✔";
				margin-right: 5px;
			}
		}

		@include gridle_state(md) {
			font-size: 1.6rem;

			a {
				font-size: 1.6rem;
			}
		}
	}

	&__cta {
		.relative-link {
			margin-top: 15px;
			padding-right: 10px;
			padding-left: 10px;
		}

		@include gridle_state(md) {
			.relative-link {
				display: inline-flex;
				margin-top: 20px;
				margin-left: auto;
				margin-right: auto;
				width: auto;
				padding-right: 30px;
				padding-left: 30px;
			}
		}
	}
}
