@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-reasons-dynamic {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 53px;
	padding-right: 53px;
	padding-top: 40px;

	@include gridle_state(md) {
		padding-top: 60px;
		padding-bottom: 55px;
		flex-direction: row-reverse;
		justify-content: center;
	}

	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include gridle_state(md) {
			width: 608px;
		}
	}

	&__icon-container {
		height: 60px;
		margin-bottom: 5px;

		@include gridle_state(md) {
			height: 72px;
			margin-bottom: 25px;
		}

		img {
			height: 100%;
		}
	}

	&__title {
		font-size: 2rem;
		margin-bottom: 18px;
		line-height: 17px;

		@include gridle_state(md) {
			font-size: 2.5rem;
			line-height: 24px;
			margin-bottom: 10px;
		}
	}

	&__texte {
		font-size: 1.4rem;
		text-align: center;

		@include gridle_state(md) {
			font-size: 1.9rem;
			line-height: 23px;
		}
	}

	&__image {
		margin-top: 35px;
		margin-bottom: 55px;

		@include gridle_state(md) {
			display: flex;
			align-items: center;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}
