@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.one-merch-banner {
	&__title {
		margin-bottom: 20px;
	}

	&__merch-title {
		margin-bottom: 10px;

		& * {
			display: inline-block;
		}
	}

	&__merch-intro {
		font-size: 1.4rem;

		@include gridle_state(sm) {
			font-size: 1.5rem;
		}
	}

	&__cta {
		margin-top: 20px;
		display: inline-flex;
		width: fit-content !important;
		padding-left: 20px !important;
		padding-right: 20px !important;

		svg {
			margin-left: 10px;
			height: 10px;
			width: 10px;
		}
	}

	&__image {
		height: 100%;
		width: 100%;
		margin-bottom: 20px;
		cursor: pointer;

		.progressive-image {
			height: 100%;
			width: 100%;
		}

		img {
			border-radius: $border-radius-big;
		}

		@include gridle_state(sm) {
			width: 350px;
			height: 196px;
			margin-right: 20px;
			margin-top: 0;
			margin-bottom: 0;
		}

		@include gridle_state(md) {
			margin-right: 50px;
		}
	}

	&__wrapper {
		display: flex;
		flex-direction: column;

		@include gridle_state(sm) {
			flex-direction: row;
			justify-content: center;
		}
	}

	&__container {
		flex: 1 1 0%;
	}
}
