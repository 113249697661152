@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$colored-product-badge-background-color: $color-primary !default;
$colored-product-badge-text-transform: none !default;
$colored-product-badge-border-radius: $border-radius-small !default;

.colored-product-badge {
	height: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0 8px;
	box-sizing: border-box;
	color: white;
	background-color: $colored-product-badge-background-color;
	border-radius: $colored-product-badge-border-radius;
	text-transform: uppercase;
	font-size: 1.15rem;
}
