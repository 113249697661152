@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.merch-carousel-block {
	.separator {
		margin-top: 20px;
		margin-bottom: 30px;
		border-width: 2px;
		width: 60px;
		background-color: $color-secondary;
	}

	&__introduction {
		margin-top: 5px;

		@include gridle_state(md) {
			font-size: 1.6rem;
		}
	}

	&__merch-title {
		margin-bottom: 20px;
	}

	.current-merch-collection__title {
		display: none;
	}
}
