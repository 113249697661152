@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.dynamic-blocks-renderer {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	&__item {
		width: 100%;
	}
}
