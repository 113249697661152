@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-reasons {
	display: grid;
	grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
	grid-gap: 30px 15px;

	@include gridle_state(md) {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 0 55px;
	}

	&__item {
		text-align: center;

		@include gridle_state(md) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	&__icon-container {
		height: 50px;
		text-align: center;
		margin-bottom: 10px;

		img {
			height: 100%;
		}

		@include gridle_state(md) {
			display: flex;
			align-items: center;
			height: 54px;
			margin-bottom: 10px;
		}
	}

	&__title {
		text-align: center;
		margin-bottom: 10px;
		min-height: 35px;
		line-height: 16px;

		@include gridle_state(md) {
			font-size: 2rem;
			min-height: 48px;
			line-height: 23px;
		}
	}

	&__texte {
		font-size: 1.4rem;
		text-align: center;
		line-height: 17px;    
		&:lang(de) {
			hyphens: auto;
		}

		@include gridle_state(md) {
			font-size: 1.5rem;
			line-height: 19px;
		}
	}
}
